import React from "react";
import { Col } from "reactstrap";


const Design = () => {
    return (
        <Col>
            <h2>Design & Attribution</h2>
            <p>Designed and built in React with Bootstrap, FontAwesome, and Inkscape using VS Code.</p>
            <p>Tap-space, avocado home, and journal-py SVGs from <a href="https://game-icons.net/">game-icons.net</a>. Favicon and yi-ren icons generated via <a href="https://favicon.io/">favicon.io</a>. Table and hand money exchange icons from <a href="https://uxwing.com/">uxwing.com</a>. Base icon for acupuncture needles from <a href="https://www.svgrepo.com/">SVG Repo</a>.</p>
        </Col>
    )
}

export default Design;