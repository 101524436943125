import React from "react";
import { Col } from "reactstrap";

const Resume = () => {
    return (
        <Col md="12">
            <h2>Resume</h2>
            <p>For my two page technical resume, please click <a href="documents/Tom_Leidy_-_Technical_Resume.pdf" target="_blank">here</a></p>
        </Col>
    )
}

export default Resume;