import { faGoodreads, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'

const about = [
    "I’m a nerd turned healer turned software developer. As a licensed acupuncturist since 2015, I've learned a lot about what I love doing, and right near the top of the list is figuring out and solving puzzles. How does mastery of Chinese Medicine transfer to technology?",
    "Aside from the fact that Yin Yang theory is directly comparable to binary, the human aspect is pervasive. So much of medicine is listening to a person, finding out what they need, and helping them on the journey to get there. In my mind, the alchemy between Patient, Practitioner, and Medicine is analogous to that between User, Developer, and Technology.",
    "One of my favorite parts of development is the process of coding once the desired testing suites are extant. The ability to measure progress on achieving specific pre-determined goals inspires laser like focus, and creates an integrated sense of reward and achievement when the whole suite passes.",
    "If you want to find me at a party, catch me attempting to make friends with a cat, doing tarot readings, or wondering who I can talk to about science or science-fiction (especially biology and Star Trek). Unless we're at a Renaissance Faire type event, then who knows?"
]


const socials = [{
    url: "https://github.com/tomleidy",
    icon: faGithub
},
{
    url: "https://www.linkedin.com/in/tom-leidy",
    icon: faLinkedin
},
{
    url: "https://www.goodreads.com/user/show/176157203-tom-leidy",
    icon: faGoodreads
}]


const projects = [
    {
        icon: "icons/hexagrams/11.svg",
        alt: "icon containing the 11th hexagram: Tai, or Peace. Heaven below Earth - a time of harmony, flow, and great progress through proper alignment.",
        title: "yi-ren",
        description: "A modern Yi Jing divination tool with coin-based hexagram generation, multiple translation support, and user reference text management",
        url: "https://github.com/tomleidy/yi-ren",
        technologies: ["React", "TypeScript", "MongoDB", "Express", "Node.js", "TailwindCSS"]
    },
    {
        icon: "icons/game-icons/bookmarklet.svg",
        description: "A Python-based tool for personal journaling with iA Writer. It prepares entries with dated filenames, astronomical/astrological information, (daily, weekly, and monthly) journaling prompts, as well as pulling Stoicism prompts from a CSV file, and updating goal word count accordingly to achieve an Artist’s Way Morning Pages style writing habit (with more!).",
        title: "journal-py",
        url: "https://github.com/tomleidy/journal-py",
        technologies: ["Python"]
    },
    {
        icon: "icons/hexagrams/63.svg",
        alt: "icon containing the 63rd hexagram: Ji Ji, or After Completion. Fire trigram over Water trigram, representing perfect order at the cusp of change.",
        title: "yi-ren-py",
        description: "The beginnings of an online Yijing reader/logger for personal or professional use",
        url: "https://github.com/tomleidy/yi-ren-py",
        technologies: ["Python", "PostgreSQL", "SQLAlchemy", "Alembic", "pytest"]
    },
    {
        icon: "icons/game-icons/tap-space.svg",
        alt: "icon depicting a target. Source: game-icons.net, 'targeting'",
        title: "tap-space",
        description: "A simple terminal game. Can you tap space when the 'racer' is in the goal? Play this to find out",
        url: "https://github.com/tomleidy/tap-space",
        technologies: ["Python", "Blessed"]
    },
    {
        icon: "icons/game-icons/avocado-home.svg",
        alt: "icon depicting a largely circular fairy figure with a crescent in the body. Source: game-icons.net, 'fairy'",
        title: "avocado home",
        description: "My implementation of a freeCodeCamp coding challenge, the creation of a secure real-time multiplayer game. Icons from game-icons.net",
        url: "https://replit.com/@tomleidy/09-infosec-p05-sec-rt-mpgame",
        technologies: ["JavaScript", "Socket.io", "Helmet.js"]
    }
]


const experience = [
    {
        icon: "icons/uxwing_based/spreadsheet.svg",
        title: "Consultant (Excel)",
        company: "Galileo Learning",
        description: "Organized and optimized a specific Excel spreadsheet to meet their staffing and operational needs. This involved creating and tailoring formulas and adjusting the layout to align with their changing specifications, ensuring the spreadsheet met end user expectations."
    },
    {
        icon: "icons/svgrepo_based/acupuncture.svg",
        title: "Owner, Acupuncturist, Herbalist",
        company: "Three Wells Acupuncture & Herbalism",
        description: "In charge of all aspects of a small business / private medical practice: finances, inventory, patient records, marketing and communication, and continuing education."
    },
    {
        icon: "icons/uxwing_based/money-exchange.svg",
        title: "Front End Team Member",
        company: "Whole Foods Market",
        description: "Accurately processed customer orders while providing possibility of pleasantries and surface level social interaction if the customer desired. Savored opportunities for 3D Tetris (bagging large orders)."
    }
]



export { projects, experience, socials, about };